import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Container, Row, Tab, Tabs
} from 'react-bootstrap';
import Hero from '@shared/hero/hero';
import { Graphql } from '@models/graphql';

import checkIcon from '@assets/images/check-icon.svg';
import './styles.scss';

export default function ContactModule(): React.ReactElement {
  const { t } = useTranslation();
  const heroData = {
    title: t('Why Rootstack'),
    margin: 'mb-5',
    relationships: {
      image: {
        name: 'bg-why-rootstack.png'
      }
    }
  } as Graphql;
  const textRows = [
    'Experienced and bilingual engineers',
    'Quick Hire',
    'Same time zone',
    'We handle +50 technologies',
    'Agile Methodologies',
    'Flex Team'
  ];
  const textHow = [
    {
      title: 'Tell us the needs of your project',
      description: "Let's have a meeting"
    },
    {
      title: 'We put together a team',
      description: 'After analyzing all our profiles'
    },
    {
      title: 'Join your local team',
      description: 'Once the dedicated software development team is in place'
    },
    {
      title: 'We build your project',
      description: 'Our team begins the execution of its tasks'
    }
  ];
  const textProfiles = [
    'Software developers',
    'Front-end and back-end developers',
    'Full-stack developers',
    'Junior, mid, and senior developers',
    'QA Engineers',
    'Web designers',
    'Experts in UX/UI design',
    'Project managers',
    'Tech leads',
    'Mobile app developers'
  ];
  const textMakeUsDifferent = [
    'Project management based on agile methodologies',
    'Competitive rates per hour',
    'Team of A+ talent with experienced developers and designers',
    'English-fluent professionals',
    'We always deliver high-quality results aligned with the industry’s higher standards'
  ];
  const textApproach = [
    {
      title: 'Constant growth',
      description: 'Now we are +100 collaborators',
    },
    {
      title: 'New technologies',
      description: 'Immersed in a world that changes at breakneck speed'
    },
    {
      title: 'Countries',
      description: 'We operate in the United States and Panama, also in Colombia and Mexico'
    }
  ];
  return (
    <>
      <Hero node={heroData} />
      <Container className="whyrootstack-container mb-5">
        <Row>
          <Col className="text-center">
            <h2>{t('Why Rootstack')}</h2>
            <p className="mt-4">{t('We are a global software development company')}</p>
          </Col>
          <Col sm={12} className="mt-5">
            <Tabs className="whyrootstack-tabs">
              <Tab eventKey="why" title={t('Why')}>
                <div className="mt-5">
                  <div className="header-container">
                    <div className="header-item mr-1" />
                    <div className="header-item focus mr-1 ml-1">ROOTSTACK</div>
                  </div>
                  {textRows.map((item, i) => (
                    <div className="row-container" key={item}>
                      <div className="item-container first mr-1">
                        <div className="item-chart">
                          <span className="item-number mr-3">{i + 1}</span>
                          {t(`${item}`)}
                        </div>
                      </div>
                      <div className="item-container mr-1 ml-1">
                        <div className="item-chart check">
                          <img src={checkIcon} alt="check" width="35px" height="35px" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Tab>
              <Tab eventKey="how" title={t('How')}>
                <Row className="justify-content-md-center mt-5">
                  <Col lg={10}>
                    {
                      textHow.map((item, i) => (
                        <Row key={item.title}>
                          <Col md="auto" className="text-center">
                            <span className="item-number">{i + 1}</span>
                          </Col>
                          <Col>
                            <h4>{t(item.title)}</h4>
                            <p>{t(item.description)}</p>
                          </Col>
                        </Row>
                      ))
                    }
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="what" title={t('What')}>
                <Row className="justify-content-md-center mt-5">
                  <Col lg={10}>
                    <h2 className="text-center mt-4">{t('What is Rootstack?')}</h2>
                    <p className="mt-4 text-align-center">{t('At Rootstack, we strive for excellence every step of the way')}</p>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="paragraph-container">
                    <span className="title-paragraph">{t('What profiles do we offer?')}</span>
                    <div className="wysiwyg-purified">
                      <ul>
                        {textProfiles.map((item) => (
                          <li key={item}>{t(item)}</li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                  <Col className="paragraph-container">
                    <span className="title-paragraph">{t('What Makes Us Different')}</span>
                    <div className="wysiwyg-purified">
                      <ul>
                        {textMakeUsDifferent.map((item) => (
                          <li key={item}>{t(item)}</li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                </Row>
                <h2 className="text-center mt-5">{t('Our approach as a company')}</h2>
                <Row sm={1} md={2} lg={3} className="whyrootstack-container mt-5">
                  {textApproach.map((item) => (
                    <Col className="paragraph-container" key={item.title}>
                      <span className="title-paragraph">{t(item.title)}</span>
                      <p className="description-paragraph">{t(item.description)}</p>
                    </Col>
                  ))}
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
}
